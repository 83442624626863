<template>
    <div class="lunbo">
        <el-form ref="mainForm" :model="mainForm" :rules="rules" label-width="80px">
            <el-form-item label="公司照片" prop="image">
                <imageUploader :imageList="getImageList" v-on:success="uploadSucess" :isMultiple="false" :showPreview="true"></imageUploader>
            </el-form-item>
            <el-form-item label="公司名称" prop="company">
                <el-input v-model="mainForm.company" style="width:300px"></el-input>
            </el-form-item>
            <el-form-item label="公司简介" prop="introduce">
                <el-input type="textarea" v-model="mainForm.introduce" :autosize="true" style="width:600px;"></el-input>
            </el-form-item>
            <el-form-item label="联系地址" prop="address">
                <el-input v-model="mainForm.address" style="width:600px"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="mobile">
                <el-input v-model="mainForm.mobile" style="width:200px"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="goBack">取消</el-button>
                <el-button type="primary" @click="submit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import imageUploader from "@/components/imageUploader";
export default {
    components: { imageUploader },
    data() {
        return {
            mainForm: {
                company: "",
                introduce: "",
                address: "",
                image: "",
                mobile: "",
            },
            rules: {
                company: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
                introduce: [{ required: true, message: "请输入公司简介", trigger: "blur" }],
                address: [{ required: true, message: "请输入联系地址", trigger: "blur" }],
                image: [{ required: true, message: "请上传图片", trigger: "blur" }],
                mobile: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
            },
            CDN: this.$cdn(),
        };
    },
    computed: {
        getImageList: function() {
            if (this.mainForm.image != "") {
                return [
                    {
                        name: this.mainForm.image,
                        url: this.CDN + this.mainForm.image,
                    },
                ];
            } else {
                return [];
            }
        },
    },
    filters: {
        getImageUrl: function(src, host) {
            return host + src;
        },
    },
    mounted() {
        this.loadContact();
    },
    methods: {
        // 后退
        goBack: function() {
            this.$router.go(-1);
        },

        uploadSucess: function(val) {
            this.mainForm.image = val;
        },
        loadContact: function() {
            this.$http({
                url: "/sys/operation/getContact",
                data: {},
            }).then((res) => {
                (this.mainForm.company = res.data.company), (this.mainForm.introduce = res.data.introduce), (this.mainForm.address = res.data.address), (this.mainForm.image = res.data.image), (this.mainForm.mobile = res.data.mobile);
            });
        },
        submit: function() {
            this.$refs.mainForm.validate((valid) => {
                if (valid) {
                    this.$http({
                        url: "/sys/operation/updateContact",
                        data: {
                            company: this.mainForm.company,
                            introduce: this.mainForm.introduce,
                            address: this.mainForm.address,
                            image: this.mainForm.image,
                            mobile: this.mainForm.mobile,
                        },
                    }).then((res) => {
                        this.$message({
                            message: "操作成功",
                            type: "success",
                        });
                        this.$router.push("/operate/contact");
                    });
                }
            });
        },
    },
};
</script>
